// import { getQuarter } from 'date-fns';
import addMonths from 'date-fns/addMonths';
import request from './request';

const TOKEN_URL =
  process.env.REACT_APP_VA_TOKEN_URL ||
  'https://rthapspanoptesfuncprod.azurewebsites.net/api/getvisiblealphatoken';

const API_URL =
  process.env.REACT_APP_VA_CONSENSUS_URL ||
  'https://rthapspanoptesfuncprod.azurewebsites.net/api/getconsensus';

// const WITH_SEMESTERS = false;

export interface IVATokenResponse {
  username: string;
  id: number;
  jwt: string;
  '2f_enabled': boolean;
}

export interface IVaConsensusResponse {
  status: string;
  data: IVaConsensusResponseDatum[];
  cut: string;
  ticker: string;
  message?: string;
  exp?: string;
}

export interface IVaConsensusResponseDatum {
  revision_date: string;
  key: string;
  data: DatumDatum[];
  revision: string;
  RevisionDateUTC: Date;
  forecast: boolean;
  analyst: string;
  analystemail: string;
  analystid: string;
  modeldate: string;
  ModelDateUTC: Date;
  initial_publish: string;
  InitialPublishUTC: Date;
  cut: string;
  growthtype: string;
  ticker: string;
  param: number;
  period: string;
  source: string;
  relative_period: string;
  period_date: string;
  PeriodDateUTC: Date;
}

export interface DatumDatum {
  period: string;
  value: number;
  param_id: number;
  universal_param_id: number;
  currency: string;
  unit: string;
  relative_period: string;
  isocurrency: string;
  valuetype: null;
  documenttype: null;
}

export interface IVAConsensusSimple {
  value: string;
  period: string;
  param: number;
  name: string;
  order: number;
  periodName: string;
  tickerName: string;
}

async function fetchAndSetToken() {
  try {
    const vaResponse = (await fetch(TOKEN_URL).then(res => res.json())) as IVATokenResponse;
    localStorage.setItem('vaResponse', vaResponse.jwt);
    localStorage.setItem('vaResponseExp', addMonths(new Date(), 1).toISOString());
    return vaResponse.jwt;
  } catch (err) {
    console.error('err', err);
  }
}

export async function getVaToken(flush?: boolean) {
  if (flush) {
    localStorage.removeItem('vaResponse');
    localStorage.removeItem('vaResponseExp');
  }
  const lsToken = localStorage.getItem('vaResponse');
  const expToken = localStorage.getItem('vaResponseExp');

  if (lsToken && expToken) {
    const now = new Date().getTime();
    const exp = new Date(expToken).getTime();
    if (now < exp) {
      return lsToken;
    }
    return fetchAndSetToken();
  }
  return fetchAndSetToken();
}

export async function getConsensusForTicker(
  ticker: string,
  vaParam: number,
  name: string,
  order: number,
  tickerName: string,
  lineItemType: string,
) {
  try {
    const token = await getVaToken();
    // const currentSemester = `H${Math.round(getQuarter(new Date()) / 2)}`;
    let params = `param=${vaParam}&cut=${lineItemType}&source=consensus&ticker=${ticker}`;

    // if (WITH_SEMESTERS) {
    //   params += `&period=${currentSemester}`;
    //   if (currentSemester === 'H1') params += '&period=Y-1';
    // }
    params += `&period=H1&period=H2&period=Y1&period=Y2&period=Y3`;

    // @ts-ignore
    let consensus: IVaConsensusResponse = await request(`${API_URL}/getparam?${params}`, {
      headers: { 'visible-authorization': `Bearer ${token}` },
    });

    if (
      (consensus && consensus.message && consensus.message.indexOf('token') !== -1) ||
      consensus?.exp === 'token expired' ||
      consensus.message?.includes('iss')
    ) {
      const token = await getVaToken(true);
      // @ts-ignore
      consensus = await request(`${API_URL}/getparam?${params}`, {
        headers: { 'visible-authorization': `Bearer ${token}` },
      });
    }

    return consensus.data.map(d => {
      const unit = d.data[0].unit;
      let suffix = d.data[0].currency;
      if (unit === '%') {
        suffix = '%';
      }

      let period = d.relative_period;
      if (period === 'Y 1') period = 'Y+1';
      if (period === 'Y 2') period = 'Y+2';

      const originalValue = d.data[0].value;
      let value = `${d.data[0].value.toFixed(2)}`;
      if (Math.abs(originalValue) === 0) value = '0';
      if (Math.abs(originalValue) >= 100000)
        value = Math.round(originalValue / 1000).toFixed(0) + 'K';
      if (Math.abs(originalValue) >= 1000000)
        value = (Math.round(originalValue / 10000) / 100).toFixed(0) + 'M';

      return {
        value: `${value}${suffix}`,
        period,
        tickerName,
        param: vaParam,
        name,
        order,
        periodName: d.period,
      };
    });
  } catch (err) {
    console.error('err', err);
    return null;
  }
}
