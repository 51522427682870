import { call, put, takeLatest, all } from 'redux-saga/effects';
import request from '../../../../utils/request';
import { IConsensus, IConsensusForTable, IConsensusPeriod, TConsensusTableRow } from './interfaces';
import { getLists, getListsSuccess, getListsFailed } from './slice';
import groupBy from 'lodash/groupBy';
import { flattenDeep, sortBy, uniqBy } from 'lodash';
import { getConsensusForTicker, IVAConsensusSimple } from 'utils/visibleAlpha';

function convertConsensusForTicker(
  consensus: Record<string, IVAConsensusSimple[]>,
  lineItemKey: string = 'name',
) {
  const tableRows = Object.keys(consensus).reduce((acc, key) => {
    const consensusForLineItem = consensus[key];
    const row = consensusForLineItem.reduce((acc2, item) => {
      // acc2[item.period] = item.value;
      acc2[item.periodName] = item.value;
      acc2.lineItem = item[lineItemKey];
      acc2.order = item.order;
      return acc2;
    }, {} as TConsensusTableRow);
    acc.push(row);
    return acc;
  }, [] as TConsensusTableRow[]);
  return tableRows;
}

function getPeriodNames(consensus: Record<string, IVAConsensusSimple[]>) {
  const periodNames: IConsensusPeriod[] = [];
  Object.keys(consensus).forEach(key => {
    const consensusForLineItem = consensus[key];
    consensusForLineItem.forEach(item => {
      periodNames.push({ period: item.period, name: item.periodName });
    });
  });
  return uniqBy(periodNames, 'period');
}

function* lists() {
  const requestURL = '/api/reports/consensus';
  try {
    const backConsensus: IConsensus[] = yield call(request, requestURL, { method: 'GET' });

    // fetch all line items for the emitter
    const emitterLineItems = backConsensus.filter((item: IConsensus) => item.isParent === true);

    // This call is done to check the token validity
    yield call(
      getConsensusForTicker,
      emitterLineItems[0].vaTicker,
      emitterLineItems[0].lineItemId,
      emitterLineItems[0].lineItemName,
      emitterLineItems[0].lineItemOrder,
      emitterLineItems[0].tickerName,
      emitterLineItems[0].lineItemType,
    );
    const consensusForEmitter: IVAConsensusSimple[][] = yield all(
      emitterLineItems.map(x =>
        call(
          getConsensusForTicker,
          x.vaTicker,
          x.lineItemId,
          x.lineItemName,
          x.lineItemOrder,
          x.tickerName,
          x.lineItemType,
        ),
      ),
    );

    const allPeriod = flattenDeep(consensusForEmitter);
    const consensusByOrder = groupBy(allPeriod, 'order');
    const periodNames = getPeriodNames(consensusByOrder);
    const tableRows = convertConsensusForTicker(consensusByOrder);

    // fetch all line items for the pairs
    const pairsLineItems = backConsensus.filter((item: IConsensus) => item.isParent === false);
    const consensusForPairs: IVAConsensusSimple[][] = yield all(
      pairsLineItems.map(x =>
        call(
          getConsensusForTicker,
          x.vaTicker,
          x.lineItemId,
          x.lineItemName,
          x.lineItemOrder,
          x.tickerName,
          x.lineItemType,
        ),
      ),
    );
    const allPeriodForPairs = flattenDeep(consensusForPairs);
    const consensusByLI = groupBy(sortBy(allPeriodForPairs, 'order'), 'name');

    const tableRows2 = Object.keys(consensusByLI).reduce((acc, key) => {
      const consensusForLineItem = consensusByLI[key];
      const consensusByCompany = groupBy(consensusForLineItem, 'tickerName');
      const tableRows = convertConsensusForTicker(consensusByCompany, 'tickerName');
      acc[key] = tableRows;
      return acc;
    }, {} as { [key: string]: TConsensusTableRow[] });

    const finalConsensus: IConsensusForTable = {
      emitter: tableRows,
      pairs: tableRows2,
      periods: uniqBy(periodNames, 'period'),
    };

    yield put(getListsSuccess(finalConsensus));
  } catch (err) {
    console.error('err', err);
    yield put(getListsFailed({ error: err.responseJSON }));
  }
}

export default [takeLatest(getLists.type, lists)];
